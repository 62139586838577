import React from "react";

function Leave(props) {
  return (
    <div className="leave-position">
    <div className="leave-container">
      <h1 className="leave-heading">Do you want to leave ?</h1>
      <button className="Leave" style={{backgroundColor:"#0e1b23",margin:"auto",fontSize:"15px",marginTop:"10px"}} onClick={props.Leave}> YES</button><br></br>
      <button className="Leave" style={{backgroundColor:"#0e1b23",margin:"auto",fontSize:"15px",marginTop:"10px"}} onClick={props.cancil}> NO</button>
    </div>
    </div>
  );
}
export default Leave
