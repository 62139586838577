import React from "react";



function LandingPage(){
    return(
        <div>
            <div className="landing-grid">
              

            </div>

        </div>
    )
}
export default LandingPage