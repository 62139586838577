import { Rating } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Slider2(props) {
    const [value, setValue] = React.useState(2);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <>
    
      <div className="rating-card">
        <div className="rating-userPhoto">
            <img src={props.photo} width="100px" style={{borderRadius:"50%"}} height="100px"></img>
        </div>
        <div className="rating-star">
         {props.name}
        </div>
        <div className="rating-star">
        <Rating name="read-only" value={props.value} readOnly />
        </div>
        <div className="rating-text">
            {props.text}
        </div>

      </div>
     
 
    </>
  );
}

export default Slider2;
