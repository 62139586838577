import React from "react";
import '../styles/friendsApp.css'
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp';
function UserCard(props){
    
    return(
        <>
        <div className="cards-main">
            <div style={{padding:"20px",textAlign:"center"}}>
            <div className="card-pic"><img width="100%" style={{borderRadius:"50%",height:"100px"}} src= {props.pic}></img></div>
            <div className="card-name">{props.name}</div>
            <div className="card-name">
            <PeopleAltSharpIcon style={{color:"white",margin:"auto"}}/>11
            </div>
            </div>
            <button style={{cursor:"pointer",border:"none"}} onClick={props.func} className="card-bottom">
                <div className="ADD">
                    {props.button}
                </div>

            </button>
        </div>
        
        
        </>

    )
}

export default UserCard