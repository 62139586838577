import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "./styles/friendsApp.css";
import UserCard from "./subcomponent/friendscards";

const Background = new URL(
  "../../images/Background Advanced.gif",
  import.meta.url
);

function UserFriend() {
  const baseUrl = "https://server.youthbuzz.in";
  const baseUrls = "http://localhost:8000";
  const id = useSelector((state) => state.get_seller_profile_id.user_id);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [search, setSearch] = useState("");
  const [name ,setName]=useState("")
  const [photo,setPhoto]=useState("")
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/user/getall`);
      setData(response.data.data.user);
      console.log(response.data.data.user);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/user/getOneuser/${id}`);
      setData2([response.data.data.user]);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (data2 === undefined || data2.length === 0) return;
   
    setName(data2[0].userName !== null ? data2[0].userName : "");
    setPhoto(data2[0].photo !==null? data2[0].photo:"")
    
  }, [data2]);

  useEffect(() => {
    fetchData();
    fetchData2();
  }, []);

  const Follow = async (id2) => {
    try {
      const response = await axios.patch(`${baseUrl}/api/v1/user/follow/${id}`, {
        UserName:name,
        id2: id2,
        friendPhoto:photo

      });
      console.log(response);
      if (response.data.status === true) {
        alert("reqsent");
      }
    } catch (error) {
      console.error(error);
      alert("Friend request already sent")
    }
  };

  // Function to check if the user is already a friend
  const isFriend = (userId) => {
    return data2[0]?.userFriendAccepted?.some(friend => friend.friendId === userId);
  };

  const filteredAchievementData = data.filter((item) => {
    return (
      (item._id.toLowerCase().includes(search.toLowerCase()) ||
      item.name.toLowerCase().includes(search.toLowerCase())) &&
      !isFriend(item._id) &&
      item._id !== id
    );
  });

  return (
    <>
      <div className="video3" style={{ paddingTop: "150px" }}>
        <div className="user-search-main" style={{ textAlign: "center" }}>
          <input
            placeholder="Search your friends"
            style={{ margin: "auto", width: "50%" }}
            className="input-form"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {filteredAchievementData.map((item) => {
              return (
                <UserCard
                  key={item._id} // Assuming _id is unique
                  name={item.userName}
                  pic={`https://youthbuzzdata.s3.ap-south-1.amazonaws.com/${item.photo}`}
                  func={() => Follow(item._id)}
                  button={"follow"}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserFriend;
