import React from "react";
import { useState } from "react";

function UpdateUserNameComp(props) {
  const [userName, setUserName] = useState("");
  const handleupdate = () => {
    props.func(userName);
  };
  return (
    <>
      <div className="userContainer">
        <h2 className="leave-heading">enter your gamename</h2>
        <input
        type="text"
        className="input-form"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="enter your username"
      ></input>
      <button className="Leave" style={{margin:"20px auto 0px auto",background:"#0e1b23"}} onClick={handleupdate}>Submit</button>
        </div>
     
    </>
  );
}

export default UpdateUserNameComp;
