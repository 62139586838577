import React, { useState } from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
function Rating2(props) {
  const [value, setValue] = useState(0);
  
  const [review, setReview] = useState("");
  const Rating2 = async () => {
 
    try {
      const res = await axios.patch(
        "https://server.youthbuzz.in/api/v1/ride/rating",
        {
          userRating: value,
          userReview: review,
          userName: props.name,
          userPhoto: props.photo,
          rideId: props.id,
        }
      );
      if (res) {
        toast.success("Thanks for Rating & Review");
        props.func()
      }
    } catch (error) {
      toast.error("Please  fill rating and review")
      console.log(error)
    }
  };

  return (
    <>
    {
      <div className="leave-position">
        <div className="leave-container">
          <h1 className="leave-heading"> Rating & Review</h1>
         
            <Stack spacing={1}>
              <Rating
                name="half-rating"
                style={{ margin: "auto" }}
                defaultValue={value}
                onChange={(e) => setValue(e.target.defaultValue)}
                precision={0.5}
                required
              />
            </Stack>
            <textarea
              value={review}
              onChange={(e) => setReview(e.target.value)}
              className="input-form"
              rows={3}
              required
              cols={20}
            ></textarea>
            <br></br>
            <button
              className="Leave"
              style={{
                background: " #0e1b23",
                width: "150px",
                margin: "5px auto",
              }}
              onClick={Rating2}
            >
              Submit
            </button>
      
            <button
              className="Leave"
              style={{
                background: " #0e1b23",
                width: "150px",
                margin: "5px auto",
              }}
              onClick={props.func}
            >
              Back
            </button>
          
        </div>
      </div>
}
    </>
  );
}
export default Rating2;
